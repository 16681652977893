var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "ml-auto",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.updatePrinter
    }
  }, [_vm._v(" Сохранить ")]), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Отмена ")])], 1), _c('b-card', {
    staticClass: "printer-device",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "printer-device__title"
  }, [_vm._v("Основные данные")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "printer-device__block-input"
  }, [_c('label', [_vm._v("Модель")]), _c('b-form-input', {
    staticClass: "printer-device__input-name-model",
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.printer.name,
      callback: function ($$v) {
        _vm.$set(_vm.printer, "name", $$v);
      },
      expression: "printer.name"
    }
  })], 1), _c('div', {
    staticClass: "printer-device__block-input"
  }, [_c('label', [_vm._v("Наименование")]), _c('b-form-input', {
    staticClass: "printer-device__input-name-printer",
    attrs: {
      "disabled": ""
    }
  })], 1), _c('div', {
    staticClass: "printer-device__block-input"
  }, [_c('label', [_vm._v("Магазин")]), _c('div', {
    staticStyle: {
      "width": "290px"
    }
  }, [_c('e-select', {
    staticClass: "e-select-el",
    attrs: {
      "options": _vm.getBranchList,
      "serchable": "",
      "max_selected_count": 1,
      "placeholder": "Выбeрите",
      "select_name": "entity_select"
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', [_c('p', {
          staticClass: "e-select-el__option-name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "e-select-el__option-description-block"
        }, [_c('p', {
          staticClass: "e-select-el__option-description"
        }, [_vm._v(_vm._s(item.address.city))]), _c('p', {
          staticClass: "e-select-el__option-description"
        }, [_vm._v(_vm._s(item.address.street))]), _c('p', {
          staticClass: "e-select-el__option-description"
        }, [_vm._v(_vm._s(item.address.apartment))])])])];
      }
    }]),
    model: {
      value: _vm.nameShop,
      callback: function ($$v) {
        _vm.nameShop = $$v;
      },
      expression: "nameShop"
    }
  })], 1)])]), _c('div', {
    staticClass: "printer-device__block-input-path"
  }, [_c('label', [_vm._v("Каталог загрузки")]), _c('b-form-input', {
    staticClass: "printer-device__input-path-printer",
    attrs: {
      "disabled": "",
      "value": _vm.pathPrinterLoading
    }
  })], 1), _c('div', {
    staticClass: "printer-device__block-chekbox"
  }, [_c('label', [_vm._v("Статус")]), _c('b-form-checkbox', {
    attrs: {
      "checked": _vm.printer.active,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        _vm.printer.active = !_vm.printer.active;
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }