<template>
  <div class="d-flex flex-column">
    <!-- <breadcrumbs :extra="device" /> -->
    <div class="d-flex">
      <b-button
        class="ml-auto"
        variant="primary"
        @click="updatePrinter"
      >
        Сохранить
      </b-button>
      <b-button
        variant="outline-primary"
        class="cursor ml-3"
        @click="goBack"
      >
        Отмена
      </b-button>
    </div>

    <b-card
      class="printer-device"
      no-body
    >
      <div class="printer-device__title">Основные данные</div>

      <div class="d-flex">
        <div class="printer-device__block-input">
          <label>Модель</label>
          <b-form-input
            v-model="printer.name"
            class="printer-device__input-name-model"
            disabled
          />
        </div>
        <div class="printer-device__block-input">
          <label>Наименование</label>
          <b-form-input
            disabled
            class="printer-device__input-name-printer"
          />
        </div>
        <div class="printer-device__block-input">
          <label>Магазин</label>
          <div style="width: 290px">
            <e-select
              v-model="nameShop"
              class="e-select-el"
              :options="getBranchList"
              serchable
              :max_selected_count="1"
              placeholder="Выбeрите"
              select_name="entity_select"
            >
              <template #custom_name="{ item }">
                <div>
                  <p class="e-select-el__option-name">{{ item.name }}</p>
                  <div class="e-select-el__option-description-block">
                    <p class="e-select-el__option-description">{{ item.address.city }}</p>
                    <p class="e-select-el__option-description">{{ item.address.street }}</p>
                    <p class="e-select-el__option-description">{{ item.address.apartment }}</p>
                  </div>
                </div>
              </template>
            </e-select>
          </div>
        </div>
      </div>
      <div class="printer-device__block-input-path">
        <label>Каталог загрузки</label>
        <b-form-input
          disabled
          class="printer-device__input-path-printer"
          :value="pathPrinterLoading"
        />
      </div>
      <div class="printer-device__block-chekbox">
        <label>Статус</label>
        <b-form-checkbox
          :checked="printer.active"
          name="check-button"
          switch
          @change="printer.active = !printer.active"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'PrinterDevice',

    apollo: {
      Device: {
        query: require('../gql/getPrinterDevice.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.params.id
          }
        },
        result({ data }) {
          this.printer = data?.Device
          this.nameShop.push(data?.Device.branch)
        },
        error(err) {
          console.log(err)
        }
      }
    },

    data() {
      return {
        printer: {},
        nameShop: [],
        pathPrinterLoading: 'C:\\BixolonPrinting2\\Input'
      }
    },

    beforeMount() {
      this.list_name = this.$route?.params?.name ?? ''
      this.visible_list = this.$route?.params?.visible
      this.breadcrumbs()
    },

    destroyed() {
      this.setBreadcrumbs({})
    },

    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),

      updatePrinter() {
        this.$apollo
          .mutate({
            mutation: require('../gql/UpdatePrinterDevice.gql'),
            variables: {
              input: {
                id: this.printer.id,
                name: this.printer.name,
                active: this.printer.active,
                branch: this.nameShop[0]?.id
              }
            }
          })
          .then(() => {
            this.$router.go(-1)
            this.$noty.show(`Принтер обнавлен`)
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`Что-то пошло не так. Попробуйте еще раз`)
          })
      },

      goBack() {
        this.$router.go(-1)
      },

      breadcrumbs() {
        if (this.$route.params?.id) {
          this.setBreadcrumbs({
            is_go_back: true,
            scales: { name: this.$route?.params?.name ?? '', visible: this.visible_list }
          })
        } else {
          this.setBreadcrumbs({ is_go_back: true, scale: { visible: this.visible_list } })
        }
      }
    },

    computed: {
      ...mapGetters({
        getBranchList: 'settings/getBranchList'
      })
    }
  }
</script>

<style lang="scss" scoped>
  .printer-device {
    margin-top: 20px;

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #bcbcbc;
      margin-bottom: 18px;
    }

    &__block-input {
      margin-right: 16px;
      margin-bottom: 20px;
    }

    &__input-name-model {
      width: 180px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &__input-name-printer {
      width: 180px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &__block-input-path {
      margin-bottom: 16px;
    }

    &__input-path-printer {
      width: 270px;
    }

    &__block-chekbox {
      ::v-deep .custom-switch .custom-control-label::before {
        height: 22px;
        left: -2.25rem;
        width: 44px;
        pointer-events: all;
        border-radius: 24.5rem;
      }
      ::v-deep .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #fff;
        transform: translateX(1.5rem);
      }
      ::v-deep .custom-switch .custom-control-label::after {
        width: 16px;
        height: 16px;
      }
      ::v-deep .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(1.4rem) !important;
      }
      ::v-deep .custom-switch .custom-control-label::after {
        top: calc(0.25rem + 3px) !important;
        left: calc(-2.25rem + 3px);
      }
    }

    ::v-deep .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #00cb91;
      background-color: #00cb91;
    }
  }

  .e-select-el {
    &__option-name {
      margin: 6px 0px 6px 0px;
      text-align: left;
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
    &__option-description-block {
      display: flex;
      margin-bottom: 6px;
    }
    &__option-description {
      margin: 0px 5px 0px 0px;
      text-align: left;
      color: var(--text-secondary-gray-400, #888);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
</style>
